/** @jsx jsx */
import useSiteMetadata from "@lekoarts/gatsby-theme-minimal-blog/src/hooks/use-site-metadata"
import { jsx, Link } from "theme-ui"


const Footer = () => {
  const { siteTitle, siteHeadline } = useSiteMetadata()

  return (
    <footer
      sx={{
        boxSizing: `border-box`,
        display: `flex`,
        justifyContent: `space-between`,
        mt: [6],
        color: `secondary`,
        a: {
          variant: `links.secondary`,
        },
        flexDirection: [`column`, `column`, `row`],
        variant: `dividers.top`,
      }}
    >
      <div>
        &copy; {new Date().getFullYear()}, Built by
        {` `}
        <Link
          aria-label="Link to my LinkedIn"
          href="https://www.linkedin.com/in/julienleleu/"
        >
          {siteTitle}
        </Link>
        . Theme inspired by
        {` `}
        <Link
          aria-label="Link to the theme author's website"
          href="https://www.lekoarts.de?utm_source=minimal-blog&utm_medium=Theme"
        >
          LekoArts
        </Link>
        .
      </div>
      <div>
        <strong>Y</strong>et <strong>A</strong>nother <strong>D</strong>ev <strong>B</strong>log !
      </div>
    </footer>
  )
}

export default Footer
